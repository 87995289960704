import React from "react";

import { Flex, Box, Text } from "flicket-ui";
import { PieChart as Chart, Pie, ResponsiveContainer, Cell } from "recharts";
import { useIsMobile } from "~hooks";
import { LegendColor } from "./Legend";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const percentage = (percent * 100).toFixed(0);

  if (Number(percentage) === 0) return null;

  return (
    <text
      x={x > cx ? x : x}
      y={y}
      fill="white"
      fontWeight="bold"
      textAnchor={x > cx ? "middle" : "middle"}
      dominantBaseline="central"
    >
      {`${percentage}%`}
    </text>
  );
};

export const PieChart = ({ data, label = null, total = null }) => {
  const isMobile = useIsMobile("sm");
  return (
    <Flex px={{ _: 0, sm: 2 }} justifyContent="flex-start">
      <Box
        height={{ _: 190, sm: 230 }}
        width={{ _: 190, sm: 230 }}
        position="relative"
        mr={{ _: 3, lg: 10 }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <Chart>
            <Pie
              cx={isMobile ? 90 : 110}
              cy="50%"
              data={data}
              innerRadius={isMobile ? 50 : 70}
              outerRadius={isMobile ? 90 : 110}
              labelLine={false}
              // @ts-expect-error
              label={renderCustomizedLabel}
              dataKey="value"
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </Chart>
        </ResponsiveContainer>

        {(label || total) && (
          <Flex
            height={{ _: 190, sm: 230 }}
            width={{ _: 190, sm: 230 }}
            position="absolute"
            left={0}
            top={0}
            flexDirection="column"
            variant="center"
          >
            {total && (
              <Text as="span" fontSize={4} fontWeight="extraBold" color="N800">
                {total.toLocaleString()}
              </Text>
            )}

            {label && (
              <Text as="span" fontWeight="extraBold" color="N800">
                {label}
              </Text>
            )}
          </Flex>
        )}
      </Box>

      <Flex flexDirection="column" justifyContent="center">
        {data?.map((item, index) => (
          <Flex alignItems="center" mb={3} key={index}>
            <LegendColor bg={item.color} />
            <Text ml="3/4">{item.label}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useMemo } from "react";
import { Text, Price } from "flicket-ui";
import { useTable, useBlockLayout } from "react-table";
import AutoSizer from "react-virtualized-auto-sizer";

import { VariableSizeList } from "react-window";
import styled from "styled-components";

import { Icon } from "~components";
import * as Bowser from "bowser";

export const browser = Bowser.getParser(
  // Needed to prevent window is not defined error
  typeof window !== "undefined" ? window.navigator.userAgent : "fake-browser"
);

const isMobileSafari =
  browser.getPlatformType() === "mobile" &&
  browser.getBrowserName() === "Safari";

const TABLE_FONT_SIZE = 16;
const WIDTH_MULTIPLIER = TABLE_FONT_SIZE / 2;
const DEFAULT_COLUMN_WIDTH = 150;

const Styles = styled.div`
  display: block;
  max-width: 100%;
  margin: 0 auto;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .table {
    width: 100%;
    border-spacing: 0;

    .tbody {
      height: ${isMobileSafari ? "calc(80vh - 150px)" : "80vh"};
      overflow: auto;

      .tr {
        &:not(.main-row) {
          .hide {
            opacity: 0;
          }
        }
      }
    }

    .thead {
      .tr {
        border-bottom: 2px solid ${(p) => p.theme.colors.N600};
      }
    }

    .tr {
      padding: 16px;
      border-bottom: 1px solid ${(p) => p.theme.colors.N200};

      &.main-row {
        /* background: linear-gradient(
          to bottom,
          ${(p) => p.theme.colors.P100} 0 57px,
          black 55px 58px,
          rgba(0, 0, 0, 0.001) 59px
        ); */

        background: ${(p) => p.theme.colors.P100};
        border-bottom: 1px solid ${(p) => p.theme.colors.N500};

        .td {
          font-weight: ${(p) => p.theme.fontWeights.extraBold};
        }
      }

      &:empty {
        display: none !important;
      }
    }

    .th,
    .td {
      margin: 0;
      font-size: ${TABLE_FONT_SIZE}px;
      font-weight: ${(p) => p.theme.fontWeights.medium};

      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }
    }

    .th {
      text-align: left;
      font-weight: ${(p) => p.theme.fontWeights.heavy};
    }
  }
`;

const cellTotalKeys = [
  "issued",
  "available",
  "holds",
  "transfers",
  "transfersPending",
  "comps",
  "sellableCapacity",
  "totalNotForSaleCount",
  "totalHoldByMembershipCount",
  "totalMembershipTicketCount",
  "soldPercentage",
  "sold",
  "baseRevenue",
  "totalWithBookingFees",
];

type CellProps = {
  info: any;
  type: "price" | "percentage" | "text";
  hidden?: boolean;
};

const Cell = ({ info, type, hidden = false }: CellProps) => {
  const value = info?.total ?? info?.value;

  if (typeof value === undefined) {
    return <></>;
  }

  if (value === 0 && !hidden) {
    return <Text>-</Text>;
  }

  if (type === "price") {
    return <Price price={value} />;
  }

  return (
    <Text {...(hidden && { as: "span", className: "hide" })}>
      {value?.toLocaleString()}
      {type === "percentage" && "%"}
    </Text>
  );
};

export const SalesTotalsTable = ({ items, id }) => {
  const scrollbarWidth = () => {
    const scrollDiv = document.createElement("div");
    scrollDiv.setAttribute(
      "style",
      "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
    );
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
  };

  const scrollBarSize = useMemo(() => scrollbarWidth(), [id]);

  const showMembershipColumns: boolean = useMemo(() => {
    // only concerned in finding the first one as that means we need to show the column
    const membershipItem = items?.find((item) => {
      return (
        item.totalHoldByMembershipCount > 0 ||
        item.totalMembershipTicketCount > 0
      );
    });
    return membershipItem !== undefined;
  }, [id]);

  const getCapacity = () =>
    items?.reduce((acc, curr) => (acc += curr.capacity), 0);

  const getTotalByKey = (types, key, row) => {
    if (typeof row[key] !== "undefined") {
      return row[key];
    }

    return types.reduce((acc, curr) => (acc += curr[key]), 0);
  };

  const getRowTotals = (row) =>
    cellTotalKeys.reduce((acc, curr) => {
      acc[curr] = getTotalByKey(row.types, curr, row);
      return acc;
    }, {});

  const formattedRows = items?.map((item) => ({
    ...item,
    subRows: item.types,
    ...getRowTotals(item),
  }));

  const getTotals = () =>
    cellTotalKeys.reduce((valuesObj, key) => {
      const sum = formattedRows?.reduce((acc, curr) => {
        return (acc += curr[key]);
      }, 0);

      valuesObj[key] =
        key === "soldPercentage" ? sum / formattedRows.length : sum;

      return valuesObj;
    }, {});

  const totalsRow = { zone: "Total", capacity: getCapacity(), ...getTotals() };

  const formatData = () => [totalsRow, ...formattedRows];

  const flatData = () => [
    totalsRow,
    ...items?.reduce((acc, curr) => {
      const obj = {
        zone: curr.zone,
        capacity: curr.capacity,
        ...getRowTotals(curr),
      };

      return acc.concat([
        ...curr.types?.map((t) => ({
          ...t,
          zone: curr.zone,
          capacity: curr.capacity,
        })),
        obj,
      ]);
    }, []),
  ];

  const data = useMemo(() => formatData(), [id]);

  const flatRows = useMemo(() => flatData(), [id]);

  const longestZoneNameLength = useMemo<number>(
    () =>
      data
        .map((row) => row.zoneName)
        .filter((name) => name)
        .reduce((a, b) => (a?.length > b?.length ? a : b), "").length,
    [data, id]
  );

  const longestTicketTypeLength = useMemo<number>(
    () =>
      data
        .flatMap((row) => row.types)
        .filter((type) => type)
        .map((type) => type.name)
        .reduce((a, b) => (a?.length > b?.length ? a : b), "").length,
    [data, id]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Tickets",
        hideHeader: true,
        columns: [
          {
            Header: "Zone",
            accessor: "zoneName",
            collapse: true,
            width:
              longestZoneNameLength * WIDTH_MULTIPLIER > DEFAULT_COLUMN_WIDTH
                ? longestZoneNameLength * WIDTH_MULTIPLIER
                : DEFAULT_COLUMN_WIDTH,
          },
          {
            Header: "Capacity",
            accessor: "capacity",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          {
            Header: "Type",
            accessor: "name",
            collapse: true,
            width:
              longestTicketTypeLength * WIDTH_MULTIPLIER > DEFAULT_COLUMN_WIDTH
                ? longestTicketTypeLength * WIDTH_MULTIPLIER
                : DEFAULT_COLUMN_WIDTH,
            Cell: (info) => <Text ellipsis>{info?.value}</Text>,
          },
          {
            Header: "Sold",
            accessor: "sold",
            collapse: true,
          },
          {
            Header: "Comps",
            accessor: "comps",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          {
            Header: "Total issued",
            accessor: "issued",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          {
            Header: "Holds",
            accessor: "holds",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          {
            Header: "Not for sale",
            accessor: "totalNotForSaleCount",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          ...(showMembershipColumns
            ? [
                {
                  Header: "Membership holds",
                  accessor: "totalHoldByMembershipCount",
                  collapse: true,
                  Cell: (info) => <Cell type="text" info={info} />,
                },
              ]
            : []),

          ...(showMembershipColumns
            ? [
                {
                  Header: "Membership tickets",
                  accessor: "totalMembershipTicketCount",
                  collapse: true,
                  Cell: (info) => <Cell type="text" info={info} />,
                },
              ]
            : []),
          {
            Header: "Sellable capacity",
            accessor: "sellableCapacity",
            collapse: true,
            Cell: (info) => <Cell type="text" hidden info={info} />,
          },
          {
            Header: "Remaining",
            accessor: "available",
            collapse: true,
            Cell: (info) => <Cell type="text" hidden info={info} />,
          },
          {
            Header: "% Sold",
            accessor: "soldPercentage",
            collapse: true,
            Cell: (info) => <Cell type="percentage" hidden info={info} />,
          },
          {
            Header: "Transfers Pending",
            accessor: "transfersPending",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          {
            Header: "Transfers",
            accessor: "transfers",
            collapse: true,
            Cell: (info) => <Cell type="text" info={info} />,
          },
          {
            Header: "Base revenue",
            accessor: "baseRevenue",
            collapse: true,
            Cell: (info) => <Cell type="price" info={info} />,
          },
          {
            Header: "Total including fees",
            accessor: "totalWithBookingFees",
            collapse: true,
            Cell: (info) => <Cell type="price" info={info} />,
          },
        ],
      },
    ],
    [id]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      flatRows,
    },
    useBlockLayout
  );

  const rowHeights = rows.map(({ subRows = [] }) => (subRows.length + 1) * 57);

  const getItemSize = (index: number) => rowHeights[index];

  const renderRowSubComponent = useCallback(({ row }) => {
    prepareRow(row);

    return row.cells.map((cell) => {
      return (
        <div
          {...cell.getCellProps()}
          className={`td ${cell.column.collapse ? "collapse" : ""}`}
        >
          {cell.render("Cell")}
        </div>
      );
    });
  }, []);

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <React.Fragment key={row.getRowProps().key}>
          <div style={style}>
            <div {...row.getRowProps()} className="tr main-row">
              {row.cells.map((cell) => {
                return (
                  <div
                    {...cell.getCellProps()}
                    className={`td ${cell.column.collapse ? "collapse" : ""}`}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
            </div>

            {row.subRows?.map((subRow) => (
              <div {...row.getRowProps()} key={subRow.id} className="tr">
                {renderRowSubComponent({ row: subRow })}
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    },
    [prepareRow, rows]
  );

  return (
    <Styles>
      <div className="tableWrap">
        <div {...getTableProps()} className="table">
          <div className="thead">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) =>
                  column.hideHeader ? null : (
                    <div
                      {...column.getHeaderProps()}
                      className={`th ${column.collapse ? "collapse" : ""}`}
                    >
                      {column.render("Header")}
                      {column.isSorted && (
                        <Icon
                          ml={1}
                          icon={
                            column.isSortedDesc
                              ? "chevron-bottom"
                              : "chevron-top"
                          }
                          fontSize={1}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            ))}
          </div>

          <div
            {...getTableBodyProps()}
            className="tbody"
            style={{ width: totalColumnsWidth + scrollBarSize }}
          >
            <AutoSizer>
              {({ height }) => (
                <VariableSizeList
                  height={height}
                  itemCount={rows.length}
                  itemSize={getItemSize}
                  width={totalColumnsWidth + scrollBarSize}
                >
                  {RenderRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default SalesTotalsTable;
